import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === 'development') {
    console.warn = () => { }
    console.error = () => { }
    console.debug = () => { }
    console.info = () => { }
}

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
