import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../contexts/authContext'
import { AssessmentOutlined, ChecklistOutlined, CloseRounded, FeedbackOutlined, PendingOutlined, ReportOffOutlined } from '@mui/icons-material'
import Tbl from '../../components/Table/Tbl'
import { columns } from '../Reports/constants'
import { useReportState } from '../../contexts/reportContext'
import { useNavigate } from 'react-router-dom'
import {
    Divider,
    Modal,
} from '@mui/material'
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { filterData } from '../../utils/helper'
import AccordionTable from '../../components/Accordion'
import CalendarView from '../../components/Calendar'
import DashboardCard from "../../components/Card/DashboardCard";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import HolidayVillageOutlinedIcon from "@mui/icons-material/HolidayVillageOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import NightShelterOutlinedIcon from "@mui/icons-material/NightShelterOutlined";

const HomeView = () => {
    const { reports } = useReportState()
    const navigate = useNavigate()

    const [filteredReports, setFilteredReports] = useState([])
    const [reportTypeFilter, setReportTypeFilter] = useState('')
    const [reportStatusFilter, setReportStatusFilter] = useState('')
    const [clerkAgentFilter, setClerkAgentFilter] = useState('')
    const [filterMinDate, setFilterMinDate] = useState('')
    const [filterMaxDate, setFilterMaxDate] = useState('')

    const { stats, user } = useAuthState()

    const [awaitingReports, setAwaitingReports] = useState(0)
    const [pendingReports, setPendingReports] = useState(0)
    const [feedbackReports, setFeedbackReports] = useState(0)
    const [completedReports, setCompletedReports] = useState(0)


    useEffect(() => {
        if (reports.length > 0) {
            setFilteredReports(filterData(reports, reportTypeFilter, reportStatusFilter, clerkAgentFilter, filterMinDate, filterMaxDate))
            setCompletedReports(reports.filter(report => report.status === 'completed').length)
            setPendingReports(reports.filter(report => report.status === 'pending').length)
            setAwaitingReports(reports.filter(report => report.status === 'awaiting').length)
            setFeedbackReports(reports.filter(report => report.status === 'feedback').length)
        }
    }, [reports, reportTypeFilter, reportStatusFilter, clerkAgentFilter, filterMinDate, filterMaxDate])

    const [calendarModalOpen, setCalendarModalOpen] = useState(false);
    const handleOpenCalendarModal = () => setCalendarModalOpen(true)
    const handleCloseCalendarModal = () => setCalendarModalOpen(false)

    return (<div className={'pb-1'}>
        <div>
            <span className={'flex text-xl md:text-2xl  font-bold mt-6'}>Dashboard</span>
        </div>
        {user?.role !== 'customer' && <>
            <div className='flex flex-col md:flex-row justify-between gap-4 md:items-center mt-6'>
                <DashboardCard url={'/customers'} title={'Customers'} stat={stats?.customer} icon={<AssignmentIndOutlinedIcon className={'text-coolBlue'} />} />
                <DashboardCard url={'/properties'} title={'Properties'} stat={stats?.property} icon={<HolidayVillageOutlinedIcon className={'text-coolBlue'} />} />
                <DashboardCard url={'/reports'} title={'Reports'} stat={stats?.report} icon={<AssignmentOutlinedIcon className={'text-coolBlue'} />} />
                <DashboardCard url={'/tenants'} title={'Tenancies'} stat={stats?.tenancies} icon={<NightShelterOutlinedIcon className={'text-coolBlue'} />} />
            </div>

            <div className='flex flex-col md:flex-row justify-between gap-4 md:items-center mt-6'>
                <DashboardCard url={'/reports'} state={"awaiting"} title={'Awaiting Reports'} stat={awaitingReports} icon={<AssessmentOutlined className={'text-coolBlue'} />} />
                <DashboardCard url={'/reports'} state={"pending"} title={'Pending reports'} stat={pendingReports} icon={<PendingOutlined className={'text-coolBlue'} />} />
                <DashboardCard url={'/reports'} state={"feedback"} title={'Feedback Reports'} stat={feedbackReports} icon={<FeedbackOutlined className={'text-coolBlue'} />} />
                <DashboardCard url={'/reports'} state={"completed"} title={'Completed Reports'} stat={completedReports} icon={<ChecklistOutlined className={'text-coolBlue'} />} />
            </div>
        </>
        }

        <div className='rounded-lg my-10 custom-calendar'>
            <CalendarView reports={reports}
                onClickHandler={handleOpenCalendarModal}
                setFilterMaxDate={setFilterMaxDate}
                setFilterMinDate={setFilterMinDate} />
        </div>

        <Modal open={calendarModalOpen} onClose={handleCloseCalendarModal}>
            <div className='flex justify-end h-screen w-full'>
                <div className='flex justify-center items-center w-full md:w-[calc(100%_-_245px)] h-screen'>
                    <div className='bg-white w-[98%] flex flex-col gap-6 py-4 rounded-lg md:mx-8'>
                        <div className='flex justify-between px-8'>
                            <span
                                className='text-lg font-semibold'>{new Date(filterMinDate).toLocaleString('en-GB', {
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric',
                                    timeZone: 'UTC',
                                })}</span>
                            <button onClick={handleCloseCalendarModal}>
                                <CloseRounded className={'text-coolBlue'} />
                            </button>
                        </div>
                        <Divider />

                        <div
                            className='hidden md:block mx-4 md:mx-8 border border-[#eeeeee] rounded-lg'>
                            <Tbl data={filteredReports} columns={columns} type={"Reports"} />
                        </div>

                        <div className='block md:hidden mt-4 mb-20'>
                            <AccordionTable data={filteredReports} type={"Reports"} />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </div>)
}

export default HomeView